import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

import {
  AUAcceptCookiesMessage,
  AULoadingManager,
  AUMainContainer,
  AUNavBar,
  AUNotificationHub,
  AUSideBar,
  AUValidateAccessProduct,
  FunctionUtils,
  PRODUCT_IDS,
} from '@assertiva/assertiva-ui';
import IMAGES from '../../constants/images';
import history from '../../history';
import routes from '../../routes';

import './style.css';

const MainLayout = ({ children, width, path }) => {
  const isMobile = isWidthDown('sm', width);
  const [openSidebar, setOpenSidebar] = React.useState(!isMobile);
  const [isLinearLoading, setLinearLoading] = React.useState(false);

  const handleSidebar = () => setOpenSidebar(!openSidebar);

  useEffect(() => {
    const loadingSub = AULoadingManager.subscribe(({ isLoading, name }) => {
      if (name === 'LINEAR') {
        setLinearLoading(isLoading);
      }
    });

    return () => {
      AULoadingManager.unsubscribe(loadingSub);
    };
  }, []);

  return (
    <div className='dashboard-layout'>
      <AUAcceptCookiesMessage />
      <AUNavBar
        handleClickIconButton={handleSidebar}
        handleClickLogoIcon={() => history.push('/dashboard')}
        logo={IMAGES.GEOTIMIZE_ICON_BAR}
        handleAfterLogout={() => {
          FunctionUtils.clearLocalStorage();
          history.push('/');
        }}
        loading={isLinearLoading}
      />

      <AUSideBar
        routes={routes}
        path={path}
        open={openSidebar}
        onClose={handleSidebar}
        onOpen={handleSidebar}
        variant={isMobile ? 'temporary' : 'permanent'}
        width={width}
        menuProps={{
          listItemProps: { showCollapseIcon: false },
        }}
      />

      <AUMainContainer isSideBarOpen={openSidebar}>
        <AUValidateAccessProduct productId={PRODUCT_IDS.GEOTIMIZE}>
          <AUNotificationHub
            productId={PRODUCT_IDS.GEOTIMIZE}
            isSideBarOpen={openSidebar}
          />
          {children}
        </AUValidateAccessProduct>
      </AUMainContainer>
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']).isRequired,
  path: PropTypes.string,
};

MainLayout.defaultProps = {
  path: '',
};

export default withWidth()(MainLayout);
